<template>
  <Modal
    class-name="i-modal productTableConWrap"
    title="选择商品"
    v-model="isShow"
    width="1300"
  >
    <Row :gutter="16" style="height: 100%">
      <i-col span="24" class="productTable" style="height: 100%">
        <Form :label-width="80" :model="goodParams" ref="productSearchForm">
          <Row :gutter="16">
            <i-col span="4">
              <FormItem label="商品ID:" prop="ProductIDs">
                <Input v-model="goodParams.ProductIDs" />
              </FormItem>
            </i-col>
            <i-col span="4">
              <FormItem label="商品名称:" prop="Name">
                <Input v-model="goodParams.Name" />
              </FormItem>
            </i-col>
            <i-col span="4">
              <FormItem label="商品品牌:" prop="BrandID">
                <Select
                  clearable
                  v-model="goodParams.BrandID"
                  placehodler="请选择商品品牌"
                >
                  <Option
                    v-for="(item, index) in productBrands"
                    :value="item.ID"
                    :style="{ marginLeft: item.Layer * 20 + 'px' }"
                    :key="index"
                    >{{ item.Name }}</Option
                  >
                </Select>
              </FormItem>
            </i-col>
            <i-col span="4">
              <FormItem label="商品分类:" prop="ClassCode">
                <Select
                  clearable
                  v-model="goodParams.ClassCode"
                  placehodler="请选择商品分类"
                >
                  <Option
                    v-for="(item, index) in productClass"
                    :value="item.ID"
                    :key="index"
                    >{{ item.Name }}</Option
                  >
                </Select>
              </FormItem>
            </i-col>
            <i-col span="4">
              <FormItem label="商品状态:" prop="Status">
                <Select
                  clearable
                  v-model="goodParams.Status"
                  placehodler="请选择商品状态"
                >
                  <Option
                    v-for="(item, index) in productStatusEnum"
                    :value="item.ID"
                    :key="index"
                    >{{ item.CN }}</Option
                  >
                </Select>
              </FormItem>
            </i-col>
            <i-col span="4" class="mt5">
              <Button
                type="primary"
                size="small"
                ghost
                @click="handleSearch"
                icon="ios-search"
                >搜索</Button
              >
              <Button class="ml2" @click="handleReset" size="small"
                >重置</Button
              >
            </i-col>
          </Row>
        </Form>

        <Row :gutter="16">
          <i-col span="14" class="productTableConProduct">
            <Table
              class="mt10 main-table"
              :loading="productTableLoading"
              :height="tableHeight"
              ref="productTable"
              :columns="productListOptions"
              :data="productObj.DataList"
              highlight-row
              size="small"
              @on-selection-change="handleSelectChange"
              @on-select-all="handleSelectAll"
              :row-class-name="rowClassName"
            >
              <template slot="DefaultImagePath" slot-scope="{ row }">
                <Avatar
                  shape="square"
                  :src="$setImagePrefix(row.DefaultImagePath)"
                ></Avatar>
              </template>
              <template slot="action" slot-scope="{ row }">
                <Button
                  size="small"
                  type="primary"
                  @click="handleAddProduct(row)"
                  >添加</Button
                >
              </template>
              <template slot="StatusCN" slot-scope="{ row }">
                <Tag
                  :color="
                    tagColor[row.Status] ? tagColor[row.Status] : 'default'
                  "
                  >{{ row.StatusCN }}</Tag
                >
              </template>
            </Table>
            <Row class="mt10">
              <i-col span="20">
                <Page
                  :total="productObj.TotalCount"
                  :current="productObj.CurrentPage"
                  @on-page-size-change="handleChangePageSize"
                  @on-change="handleChangePage"
                  placement="top"
                  size="small"
                  show-elevator
                  show-sizer
                  show-total
                />
              </i-col>
              <i-col span="4" style="text-align: right">
                <Button
                  size="small"
                  type="info"
                  @click="handleAddProduct(productSelections)"
                  >批量添加</Button
                >
              </i-col>
            </Row>
          </i-col>

          <i-col span="10">
            <Table
              class="mt10"
              :height="tableHeight"
              ref="selectedProductTable"
              :columns="selectedProductListOptions"
              :data="selectedProduct"
              highlight-row
              size="small"
              @on-selection-change="handleSelectedProductTableSelectChange"
              @on-select-all="handleSelectedProductTableSelectAll"
            >
              <template slot="DefaultImagePath" slot-scope="{ row }">
                <Avatar
                  shape="square"
                  :src="$setImagePrefix(row.DefaultImagePath)"
                ></Avatar>
              </template>
              <template slot="action" slot-scope="{ index }">
                <Button
                  size="small"
                  type="error"
                  @click="handleRemoveProduct(index)"
                  >移除</Button
                >
              </template>
              <template slot="StatusCN" slot-scope="{ row }">
                <Tag
                  :color="
                    tagColor[row.Status] ? tagColor[row.Status] : 'default'
                  "
                  >{{ row.StatusCN }}</Tag
                >
              </template>
            </Table>
            <Row style="text-align: right">
              <Button
                class="mt10"
                size="small"
                type="error"
                @click="handleRemoveProduct(selectedProductSelections)"
                >批量移除</Button
              >
            </Row>
          </i-col>
        </Row>
      </i-col>
    </Row>
    <template slot="footer">
      <Button @click="isShow = false">取消</Button>
      <Button type="primary" @click="emitProduct">确认</Button>
    </template>
  </Modal>
</template>

<script>
import api from "@/api";
import bizEnum from "@/libs/bizEnum";
import { mapActions } from "vuex";
export default {
  name: "sales-good-modal",
  data() {
    return {
      productTableLoading: false,
      tableHeight: 0,
      isShow: false,
      tagColor: {
        0: "volcano",
        1: "success",
        2: "warning",
      },
      productListOptions: [
        { type: "selection", width: 43, align: "center" },
        {
          title: "商品图片",
          key: "DefaultImagePath",
          align: "center",
          slot: "DefaultImagePath",
          width: 100,
        },
        { title: "商品ID", key: "ID", width: 80 },
        { title: "商品名称", key: "Name", minWidth: 160 },
        { title: "商品品牌", key: "BrandCN", width: 120 },
        { title: "商品分类", key: "ClassCN", width: 120 },
        { title: "商品状态", key: "StatusCN", slot: "StatusCN", width: 100 },
        {
          title: "操作",
          key: "action",
          slot: "action",
          fixed: "right",
          width: 60,
        },
      ],
      selectedProductListOptions: [
        { type: "selection", width: 43, align: "center" },
        {
          title: "商品图片",
          key: "DefaultImagePath",
          align: "center",
          slot: "DefaultImagePath",
          width: 100,
        },
        { title: "商品ID", key: "ID", width: 80 },
        { title: "商品名称", key: "Name", width: 160 },
        {
          title: "操作",
          key: "action",
          slot: "action",
          fixed: "right",
          width: 60,
        },
      ],
      selectedProduct: [],
      goodParams: {
        ProductIDs: "",
        Name: "",
        BrandID: "",
        ClassCode: "",
        Status: "",
        ProductType: "",
        Page: 1,
        PageSize: 10,
        SortName: "CreateDate",
        SortOrder: "Desc",
      },
      productObj: {
        CurrentPage: 1,
        TotalCount: 0,
        TotalPage: 0,
        DataList: [],
      },
      productSelections: [],
      selectedProductSelections: [],
      loadNum: 0,
    };
  },
  watch: {
    isShow: function (val) {
      if (val) {
        this.loadProduct();
      }
    },
  },
  async created() {
    await this.getProductBrandInfo();
    await this.getProductClassList();
  },
  methods: {
    ...mapActions(["getProductBrandInfo", "getProductClassList"]),
    emitProduct() {
      if (!this.selectedProduct.length) {
        return this.$Message.error("请添加商品");
      }
      this.$emit("getEmitProduct", this.selectedProduct);
      this.isShow = false;
    },
    handleSelectedProductTableSelectChange(selection) {
      this.selectedProductSelections = selection;
    },
    handleSelectedProductTableSelectAll(selections) {
      this.selectedProductSelections = selections;
    },
    handleSelectChange(selection) {
      this.productSelections = selection;
    },
    handleSelectAll(selections) {
      this.productSelections = selections;
    },
    rowClassName(row) {
      if (this.selectedProduct.find((item) => item.ID === row.ID)) {
        return "tdSelected";
      } else {
        return "";
      }
    },
    // 移除商品
    handleRemoveProduct(index) {
      if (typeof index === "number") {
        // 单条移除
        this.selectedProduct.splice(index, 1);
      } else {
        this.selectedProductSelections.forEach((item) => {
          const i = this.selectedProduct.findIndex(
            (product) => product.ID === item.ID
          );
          if (i !== -1) {
            this.selectedProduct.splice(i, 1);
          }
        });
      }
    },
    // 添加商品
    handleAddProduct(row) {
      if (row.constructor === Object) {
        // 单条添加
        const hasProduct = this.selectedProduct.find(
          (item) => item.ID === row.ID
        );
        if (!hasProduct) {
          this.selectedProduct.push(JSON.parse(JSON.stringify(row)));
        }
      } else {
        // 批量添加
        row.forEach((item) => {
          const hasProduct = this.selectedProduct.find(
            (product) => product.ID === item.ID
          );
          if (!hasProduct)
            this.selectedProduct.push(JSON.parse(JSON.stringify(item)));
        });
      }
    },
    handleChangePageSize(pagesize) {
      this.goodParams.Page = 1;
      this.goodParams.PageSize = pagesize;
      this.loadProduct();
    },
    handleChangePage(page) {
      this.goodParams.Page = page;
      this.loadProduct();
    },
    handleSearch() {
      this.goodParams.Page = 1;
      this.loadProduct();
    },
    handleReset() {
      this.$refs.productSearchForm.resetFields();
      this.loadProduct();
    },
    async loadProduct() {
      this.productTableLoading = true;
      try {
        let res = await api.GetProductList(this.goodParams);

        res.Data.DataList.forEach((item) => {
          let FPB = this.productBrands.find((b) => {
            return b.ID === item.BrandID;
          });
          item.BrandCN = FPB ? FPB.Name : "";
          let FPC = this.productClass.find((c) => c.Code === item.ClassCode);
          item.ClassCN = FPC ? FPC.Name : "";
        });
        this.productObj = res.Data;
        this.productTableLoading = false;

        this.$nextTick(() => {
          if (!this.loadNum) {
            // 不知道这里为什么查出来 两条productTableConProduct节点 所以单独计算
            const main = Array.prototype.find.call(
              document.getElementsByClassName("productTableConProduct"),
              (e) => e.offsetHeight > 0
            );
            const mainH = main.offsetHeight;
            let h = 0;
            const forEach = (arr, fn) => {
              if (!arr.length || !fn) return;
              let i = -1;
              let len = arr.length;
              while (++i < len) {
                let item = arr[i];
                fn(item, i, arr);
              }
            };
            forEach(main.children, (item) => {
              if (!item.classList.contains("main-table")) {
                h += item.offsetHeight;
              }
            });
            this.tableHeight = mainH - h - 20;
          }
          this.loadNum++;
        });
      } catch (error) {
        this.$Notice.error({ title: "错误", desc: error });
        this.productTableLoading = false;
      }
    },
  },
  computed: {
    productBrands() {
      return this.$store.state.product.brandList || [];
    },
    productClass() {
      return this.$store.state.product.classList || [];
    },
    productStatusEnum() {
      return bizEnum.ProductStatusEnum;
    },
  },
};
</script>
<style lang="less">
.productTableConWrap {
  .ivu-table-body {
    padding-bottom: 0 !important;
  }
}
</style>
